import React from 'react';
import { Link } from 'gatsby';

import BackButton from '../components/common/back-button';
import Row from '../components/common/row';
import Column from '../components/common/column';
import AppStore from '../components/common/app-store';
import GooglePlay from '../components/common/google-play';

import "../components/projects/projects.css";

import main from '../images/projects/cook-it/main.png';
import screen1 from '../images/projects/cook-it/screen1.png';
import screen2 from '../images/projects/cook-it/screen2.png';
import screen3 from '../images/projects/cook-it/screen3.png';
import screen4 from '../images/projects/cook-it/screen4.png';
import screen5 from '../images/projects/cook-it/screen5.png';
import Colors from '../constants/Colors';

const CookItPage = () => {
  return (
    <div className="project-container cook-it">
      <div className="content-fluid">
        <Link to="/">
          <BackButton color={Colors.cookIt} />
        </Link>
        <div className="header">
          <div className="title">
            Cook It!
          </div>
          <Row justify="space-between">
            <Column className="column-padded" justify="center">
              <p className="description">
                Cook it is an application to store your favorite recipes.
              </p>
            </Column>
            <Column className="image-container">
              <img src={main} alt="Cook It" />
            </Column>
          </Row>
        </div>
        <div className="content">
          <p className="description">
            My friends and I always share recipes via chat, but after a while it is impossible to find them!
          </p>
          <p className="description">
            So I thought, what could be better than having my own recipe book always on my phone?
          </p>
          <p className="description">
            And so I started developing Cook it
          </p>
          <p className="description">
            - Create your own cookbook
          </p>
          <p className="description">
            - Find recipes from your friends easily
          </p>
          <p className="description">
            - Save your favorite recipes
          </p>
          <br />
          <p className="description">
            {`Homemade is always better,\nLet's cook it!`}
          </p>
        </div>
      </div>
      <div className="gallery">
        <img src={screen1} alt="Cook It" />
        <img src={screen2} alt="Cook It" />
        <img src={screen3} alt="Cook It" />
        <img src={screen4} alt="Cook It" />
        <img src={screen5} alt="Cook It" />
      </div>
      <div className="footer">
        <div className="content">
          {/* <div className="note">
            This application is available for iOS and Android in Argentina and Uruguay for now.
          </div> */}
          <div className="download">
            DOWNLOAD
          </div>
          <div className="download-buttons">
            <AppStore link="https://apps.apple.com/us/app/cook-it/id1511429751" />
            <GooglePlay link="https://play.google.com/store/apps/details?id=com.ns.cookit.app"/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CookItPage;